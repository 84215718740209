.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-text {
    position: relative;
    cursor: pointer;
  }
  
  .tooltip-text:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
  
  .tooltip-content {
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    white-space: nowrap;
  }
  